import React, { useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { graphql } from "gatsby";
import { FormattedMessage, Link, injectIntl } from "gatsby-plugin-intl";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Grid from "styled-components-grid";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import BlockText from '../components/block-text'
import Container from "../components/container";
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import GraphQLErrorList from "../components/graphql-error-list";
import SideBarLeft from "../components/sidebar-left";
import SideBarRight from "../components/sidebar-right";

import Content, { ContentDivider, ContentHeader, ContentImage } from "../components/content";
import { Heading2 } from "../components/heading";
import Mono, { MonoUppercase } from "../components/mono";
import RatioBox from "../components/ratio-box";
import SEO from "../components/seo";
import { FixedImage } from "../components/layout";

export const query = graphql`
  query WorkshopTemplateQuery($id: String!) {
    workshop: sanityWorkshop(id: { eq: $id }) {
      id
      slug {
        current
      }
      title {
        fi
        en
      }
      author
      color1
      color2
      publishedAt
      mainImage {
        _key
        _type
        caption
        alt
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          id
          url
          fixed {
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      galleryImages {
        _key
        _type
        caption
        alt
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          id
          url
          fixed {
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      _rawBody
      website
      instagram
      twitter
      _rawEmbedCode
    }
  }
`;

const WorkshopTemplate = ({ intl, ...props }) => {
  const { data, errors } = props;
  const node = data && data.workshop;
  const locale = intl.locale || "en";

  return (
    <React.Fragment>
      {errors && <SEO title="GraphQL Error" />}
      {node && <SEO title={node.author || "Untitled"} locale={locale} lang={locale} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      <Grid bottom={false}>
        <Grid.Unit size={{ xs: 1 / 1 }}>
          <StyledContent bgColor="orange" fgColor="yellowish">
            <StyledContentHeader>
              <Heading2 fgColor="yellowish">{node.author}</Heading2>
              {node.title && (
                <StyledTitle>
                  {node.title[intl.locale]}
                </StyledTitle>
              )}
            </StyledContentHeader>
            <StyledImageContainer>
              <div className="speaker-main-image" style={{ 'backgroundImage': `url(${imageUrlFor(buildImageObj(node.mainImage))})` }}></div>
            </StyledImageContainer>
            </StyledContent>
            <StyledFlexContent bgColor="yellowish" fgColor="orange">
              <SideBarLeft word={node.author} fill="var(--color-orange)"></SideBarLeft>
              <StyledPanelContentCenter id="center-scroller">
              <ContentWrapper>
                <StyledContentDivider />
                <MainContent>
                  <BlockText blocks={node._rawBody[intl.locale]} />
                </MainContent>
                <StyledContentDivider />
                {(node.website || node.instagram || node.twitter) && (
                  <StyledWebsiteLink>
                    {node.website && (
                      <a href={node.website} target="_blank" className="button-link">WWW</a>
                    )}
                    {node.instagram && (
                      <a href={node.instagram} target="_blank" className="button-link">Instagram</a>
                    )}
                    {node.twitter && (
                      <a href={node.twitter} target="_blank" className="button-link">Twitter</a>
                    )}

                  </StyledWebsiteLink>
                )}
              {(node.galleryImages && node.galleryImages.length > 1) && (
                <React.Fragment>
                <Slideshow images={node.galleryImages}></Slideshow>
                </React.Fragment>
              )}
              </ContentWrapper>
            </StyledPanelContentCenter>
            <SideBarRight word={node.author} fill="var(--color-orange)"></SideBarRight>
          </StyledFlexContent>
        </Grid.Unit>
      </Grid>
    </React.Fragment>
  );
};

class FixedImagePortal extends React.Component {
  render() {
    const domNode = document.getElementsByTagName('body')[0];

    return ReactDOM.createPortal(
      this.props.children,
      domNode
    );
  }
}

const StyledContentDivider = styled(ContentDivider)`
  width: 17%;
  margin: 40px 0;
  height: 1px;
  ${breakpoint("md")`
    margin: 20px 0;
  `}
`;

const MainContent = styled.div`
  margin: 70px 0;
`;

const StyledContentImage = styled(ContentImage)`
  ${props => props.overlayColor && `
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: var(--color-${props.overlayColor});
      mix-blend-mode: screen;
    }
  `}
`;

const StyledImageContainer = styled.div`
  text-align: center;
`;

const StyledImage = styled.img`
   max-width: 80%;
  ${breakpoint("md")`
    margin: 20px auto;
    max-width: 600px;
  `}
`;

const StyledContent = styled(Content)`
  padding: 15px 20px;
  ${breakpoint("md")`
    padding: 160px 5px 60px 5px;
  `}
`;

const StyledTitle = styled.div`
  margin-top: 35px;
`;

const StyledContentHeader = styled(ContentHeader)`
  margin-top: 20px;
  margin-bottom: 30px;
  color: var(--color-white);

  ${breakpoint("md")`
    margin-top: 0;
    margin-bottom: 50px;
  `}
  h2 {
    font-family: var(--font-family-header);
    margin-bottom: 10px!important;
    text-transform: capitalize;
    font-weight: normal;
    font-size: 60px;
    line-height: 50px;
    ${breakpoint("md")`
      font-size: 180px;
      line-height: 140px;
    `}
  }
`;

const StyledRatioBox = styled.div`
  ${breakpoint("md")`
    display: none;
  `}
`;

const ContentWrapper = styled.div`
  max-width: 600px;
  margin: auto;
  p {
    font-size: 18px;
    line-height: 22px;
    ${breakpoint("md")`
    font-size: 25px;
    line-height: 30px;
  `}
  }
`;

const StyledWebsiteLink = styled.div`
  a {
    font-family: var(---font-family-bold);
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    padding-bottom: 12px;
    &:after {
      content: '↗';
      font-size: 16px;
      font-family: var(--font-family-bold);
      font-weight: 700;
      margin-left: 5px;
    }
    ${breakpoint("md")`
    font-family: var(---font-family-bold);
    font-size: 18px;
    line-height: 21px;
    &:after {
      font-size: 18px;
    }
  `}
  }
`;

const StyledPanelContentCenter = styled.div`
  flex-basis: 100vw;
  flex-basis: 100vw;
  margin-bottom: 40px;
  ${breakpoint("md")`
    margin-bottom: 0;
  `}
`;

const StyledFlexContent = styled(Content)`
  overflow: hidden;
  display: flex;
  .default-nav {
    display: none;
  }
  padding: 15px 20px;
  ${breakpoint("md")`
    padding: 60px 5px;
  `}
`;

const StyledGalleryComponentWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  ${breakpoint("md")`
  `}

  .react-photo-gallery--gallery {
    margin: 0 -4px 0 -2px;

    ${props => props.overlayColor && `
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: var(--color-${props.overlayColor});
        mix-blend-mode: screen;
        pointer-events: none;
      }
    `}

    img {
      filter: grayscale(100%);
      margin: 1px !important;
      display: block;
    }
  }
`;

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '600px',
  width: '100%',
  marginTop: '50px'
}


const Slideshow = (props) => {
  return (
    <div className="slide-container">
      <Slide>
       {props.images.map((slideImage, index)=> (
          <div key={index}>
            <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.asset.url})` }}>
            </div>
          </div>
        ))} 
      </Slide>
    </div>
  )
}

const GalleryComponent = (props) => {
  const { node } = props;
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const gallery = [];

  const customStyles = {
    blanket: (base, state) => ({
      ...base,
      background: 'var(--color-yellow) !important'
    }),
    header: (base, state) => ({
      ...base,
      background: 'none !important'
    }),
    headerClose: (base, state) => ({
      ...base,
      color: 'white !important'
    }),
    navigationPrev: (base, state) => ({
      ...base,
      background: 'none !important'
    }),
    navigationNext: (base, state) => ({
      ...base,
      background: 'none !important'
    }),
    footer: (base, state) => ({
      ...base,
      background: 'none !important'
    }),
    footerCount: (base, state) => ({
      ...base,
      color: 'white !important',
      fontSize: '14px !important',
      textTransform: 'uppercase',
      letterSpacing: '0.03em'
    })
  }

  node.galleryImages.map((image, index) => {
    const imageUrl = imageUrlFor(buildImageObj(image))
      .width(1200)
      .maxWidth(1200)
      .maxHeight(1200)
      .auto('format')
      .quality(70)
      .url();

    const obj = {
      src: imageUrl,
      width: image.asset.fixed.width,
      height: image.asset.fixed.height
    }
    gallery.push(obj);
  });


  return (
    <React.Fragment>
      <Gallery photos={gallery} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal allowFullscreen={false} onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              styles={customStyles}
              hideControlsWhenIdle={false}
              views={gallery.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </React.Fragment>
  );
};

export default injectIntl(WorkshopTemplate);
